import React, { useState, useEffect } from 'react';
import { Table, Modal, Form, Input, Button, Select, message } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import { listarColaboradores, atualizarColaborador, inserirColaborador } from '../services/serviceColaborador';
import {sessaoUsuarioBasico, sessaoUsuario} from '../services/serviceLogin'

import SelectEmpresa, { fLabelEmpresa } from '../component/selectEmpresa'

let vGlobalEmpresaSelecionada

const App = (props) => {
    const [modalColaborador, setmodalColaborador] = React.useState(false)
    const [vAcao, setAcao] = React.useState('')
    const [messageApi, contextHolder] = message.useMessage();


    //const [statusColaborador, setStatusColaborador] = React.useState();

    const vEditBranco = {
        matricula: '',
        cpf: '',
        nome: '',
        estado: '',
        cidade: '',
        bairro: '',
        endereco: '',
        numero: '',
        cep: '',
        email: '',
        setor: '', 
        centroCusto: '',
        telefoneContato: '',
        observacoes: '',
        status: 'Ativo'
    }
    const [objEdit, setObjEdit] = React.useState(vEditBranco)
    const [tabelaColaboradores, setTabelaColaboradores] = React.useState([])
    const fEditar = (pObj) =>{
        //setStatusColaborador(objEdit.status)
        setAcao('Atualizar')
        setmodalColaborador(true)
        //console.log(pObj)
        setObjEdit(pObj)
        //console.log(objEdit)
    }
    
    const columnsTabelaColaboradores = [
        {
            render: (_, record) => (
                <Button type="primary" onClick={() => fEditar(record)} shape="circle" icon={<EditOutlined />} />
            )
            
        },
        
        {
            title: 'Matricula',
            dataIndex: 'matricula',
            key: 'matricula',
        },
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
        },
        {
            title: 'Centro de Custos',
            dataIndex: 'centroCusto',
            key: 'centroCusto',
        },
        {
            title: 'Setor',
            dataIndex: 'setor',
            key: 'setor',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ]


    async function cargaColaboradores() {
        setTabelaColaboradores(await listarColaboradores({ Nome: "", idEmpresa: vGlobalEmpresaSelecionada }))
    }

    async function iniciar(){
        const vUsuario = await sessaoUsuario();
        setEmpresaSelecionada(vUsuario.idEmpresa);
        setLiberarSelectEmpresa(vUsuario.nivelAcesso === 'adm' ? false : true)

        cargaColaboradores()

        //setTabelaColaboradores(await listarColaboradores({ Nome: "" }))
    }
    useEffect(() => {
        // Coloque aqui o código que você quer executar logo após o app iniciar
        iniciar()
        //console.log('tabelaColaboradores')
        //console.log(tabelaColaboradores)
        console.log('O aplicativo foi iniciado!');
    },

        []);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setObjEdit(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSelectChange = (value) => {
        //setStatusColaborador(value);
        setObjEdit(prevState => ({
            ...prevState,
            status: value,
        }));
    };

    const fNovo = () =>{
        setAcao('Inserir')
        setObjEdit(vEditBranco)
        setmodalColaborador(true)
    }

    async function  fEnviarFormulario  () {
        if (vAcao === 'Inserir') {
            const vRetorno = await inserirColaborador(objEdit)
            console.log('vRetorno')
            console.log(vRetorno)
            if ((vRetorno && vRetorno.status === 'Ok') || (vRetorno && vRetorno.success)) {
                messageApi.open({
                    type: 'success',
                    content: vRetorno.descricao,
                });
                cargaColaboradores()
                setmodalColaborador(false)
                setAcao('')
                //console.log('fLimparForm()')
                //await fLimparForm()
            } else {
                messageApi.open({
                    type: 'error',
                    content: vRetorno.descricao,
                });
            }

        }

        if (vAcao === 'Atualizar') {
            const vRetorno = await atualizarColaborador(objEdit)
            //console.log('vRetorno')
            //console.log(vRetorno)
            if ((vRetorno && vRetorno.status === 'Ok') || (vRetorno && vRetorno.success)) {
                messageApi.open({
                    type: 'success',
                    content: vRetorno.descricao,
                });
                cargaColaboradores()
                setmodalColaborador(false)
                setAcao('')
                //console.log('fLimparForm()')
                //await fLimparForm()
            } else {
                messageApi.open({
                    type: 'error',
                    content: vRetorno.descricao,
                });
            }

        }
        
        
        
    }

    const [vEmpresaSelecionada, setEmpresaSelecionada] = React.useState()
    const [vLiberarSelectEmpresa, setLiberarSelectEmpresa] = useState(true);

    
    const handleEmpresaChange = (value) => {
        //const vRetorno = objTarifa
        //vRetorno.idEmpresa = value
        //setObjTarifa(vRetorno)
        vGlobalEmpresaSelecionada = value
    
        console.log('vGlobalEmpresaSelecionada')
        console.log(vGlobalEmpresaSelecionada)
        setEmpresaSelecionada(value);
        cargaColaboradores()
      };

    return (
        <>
        {contextHolder}
        <Button icon={<PlusOutlined />} onClick={fNovo}>Adicionar novo Colaborador</Button>
        
        <SelectEmpresa value={vEmpresaSelecionada} disabled={vLiberarSelectEmpresa} onChange={handleEmpresaChange}></SelectEmpresa>


            <Table columns={columnsTabelaColaboradores} dataSource={tabelaColaboradores}></Table>
            <Modal title="Editar colaboradores" onCancel={ () => {setmodalColaborador(false)}} onOk={fEnviarFormulario} open={modalColaborador}>
                <Form>
                    <Form.Item label="Matrícula">
                        <Input 
                            name='matricula'
                            value={objEdit.matricula}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="CPF">
                        <Input 
                            name='cpf'
                            value={objEdit.cpf}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Nome">
                        <Input 
                            name='nome'
                            value={objEdit.nome}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Estado">
                        <Input 
                            name='estado'
                            value={objEdit.estado}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Cidade">
                        <Input 
                            name='cidade'
                            value={objEdit.cidade}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Bairro">
                        <Input 
                            name='bairro'
                            value={objEdit.bairro}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Endereço">
                        <Input 
                            name='endereco'
                            value={objEdit.endereco}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Número">
                        <Input 
                            name='numero'
                            value={objEdit.numero}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="CEP">
                        <Input 
                            name='cep'
                            value={objEdit.cep}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="E-mail">
                        <Input 
                            name='email'
                            value={objEdit.email}
                            onChange={handleInputChange}
                            placeholder="@com.br" />
                    </Form.Item>
                    <Form.Item label="Setor">
                        <Input 
                            name='setor'
                            value={objEdit.setor}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Centro Custos">
                        <Input 
                            name='centroCusto'
                            value={objEdit.centroCusto}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Telefone Contato">
                        <Input 
                            name='telefoneContato'
                            value={objEdit.telefoneContato}
                            onChange={handleInputChange}
                            placeholder="" />
                    </Form.Item>
                    <Form.Item label="Observações">
                        <Input 
                            name='observacoes'
                            value={objEdit.observacoes}
                            onChange={handleInputChange}
                            placeholder="Observações" />
                    </Form.Item>
                    <Form.Item label="Status">
                        <Select 
                            value={objEdit.status}
                            onChange={handleSelectChange}
                            options={
                            [
                                { value: 'Ativo', label: 'Ativo' },
                                { value: 'Desativado', label: 'Desativado' }]
                            } ></Select>
                    </Form.Item>

                </Form>

            </Modal>
        </>
    );



};

export default App;