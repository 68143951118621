import React, { useState, useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';

import { atualizarSenha } from '../services/serviceUsuario';
import { sessaoUsuarioBasico } from '../services/serviceLogin';

const App = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRept, setNewPasswordRepet] = useState('');
  const [vSessao, setSessao] = useState('');

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  async function handleLogar() {
    console.log("Acesso Logar");
    if (newPassword === newPasswordRept){
    const vRetorno = await atualizarSenha({
      password: password,
      newpassword: newPassword,
      company: vSessao.idEmpresa,
      username: vSessao.email
    });
    console.log('vRetorno')
    console.log(vRetorno)
    //if (vRetorno.success) {
      if (vRetorno && vRetorno.status !=='Erro') {
      messageApi.open({
        type: 'success',
        content: vRetorno.descricao,
      });

      form.setFieldsValue({
        password: '',
        newpassword: '',
        newpasswordRepet: '',
      });
      //window.location.reload();
    } else {
      messageApi.open({
        type: 'error',
        content: vRetorno.descricao,
      });
    }
    console.log(vRetorno);
  }else{

    messageApi.open({
      type: 'error',
      content:'Senhas diferentes',
    });
  }
  }

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    cargaInicial();

    async function cargaInicial() {
      const cSessao = await sessaoUsuarioBasico();
      console.log(cSessao)
      setSessao(cSessao);
      form.setFieldsValue({
        username: cSessao.email,
      });
      console.log("Sessao");
      console.log(cSessao.email);
    }
  }, [form]);

  return (
    <>
      {contextHolder}

      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="E-mail"
          name="username"
          
        >
          <Input  disabled={true}/>
        </Form.Item>

        <Form.Item
          label="Senha Atual"
          name="password"
          rules={[{ required: true, message: 'Informe sua senha!' }]}
        >
          <Input.Password onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Nova Senha"
          name="newpassword"
          rules={[{ required: true, message: 'Informe sua senha!' }]}
        >
          <Input.Password onChange={(e) => setNewPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Repetir nova senha"
          name="newpasswordRepet"
          rules={[{ required: true, message: 'Informe sua senha!' }]}
        >
          <Input.Password onChange={(e) => setNewPasswordRepet(e.target.value)} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button onClick={handleLogar} type="primary" htmlType="submit">
            Alterar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default App;
