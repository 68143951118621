import React from 'react';
import ReactDOM from 'react-dom/client';
//import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
/*import { LogoutOutlined } from '@ant-design/icons';
import {
  Button,
} from 'antd';
*/
import './index.css';



import CompLayout from './component/compLayout'
import Login from './component/login'
import CompLayoutSolic from './component/compLayoutSolic'
import CompLayoutRH from './component/compLayoutRH'

import reportWebVitals from './reportWebVitals';

import { sessaoUsuario as consultarSessao, /*logout as fSair*/ } from './services/serviceLogin'


import ptBR from "antd/es/locale/pt_BR";

import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById('root'));
const vRetorno = await consultarSessao()

//console.log("xxxxxxx")
//console.log(vRetorno)
root.render(
  /*<React.StrictMode>*/
  < ConfigProvider locale={ptBR}>
    {
      (() => {
        /*function handlerSair(){
          fSair()
          window.location.reload();
        }*/

        if (vRetorno.autenticado) {
          switch (vRetorno.nivelAcesso) {
            case 'adm':
              return <CompLayout />
            case 'solic':
              return <div>
                <CompLayoutSolic />
                  </div>  
              case 'rh':
                return <div>
                  <CompLayoutRH />
                  </div>             
            default:
              return <CompLayoutSolic />              
          }

        } else {
          return <div className="centered-container"><Login /></div>;
        }
      })()
    }


   
  </ConfigProvider>
  /* </React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
