import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined,  HomeOutlined, LogoutOutlined, LoginOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, Button, Image } from 'antd';

import CadastroTipoVeiculo from '../pages/cadastroTipoVeiculo';
import CadastroMensagem from '../pages/cadastroMensagem'
import CadastroTarifa from '../pages/cadastroTarifa'
import CadastroRota from '../pages/cadastroRota'
import SolicitacaoTransporteExtra from '../pages/solicitacaoTransporteExtraN'
import CadastroUsuario from '../pages/cadastroUsuario'
import CadastroColaboradores from '../pages/cadastroColaborador'
import AlterarSenha from '../pages/alterarSenha'

import ExtratoTransporteExtra from '../pages/extratoTransporteExtra'
import Login from '../component/login'
import { 
  logout as fSair, atualizarAcesso } from '../services/serviceLogin'

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
   getItem(<Link to="">Início</Link>, '00', <HomeOutlined />),
    getItem('Cadastros', '0', <DesktopOutlined />, 
    [
    getItem(<Link to="cadastroTipoVeiculo">Cadastro Tipo Veículo</Link>, '0.1'),
    getItem(<Link to="cadastroMensagem">Cadastro Mensagem</Link>, '0.2'),
    getItem(<Link to="cadastroTarifa">Cadastro Tarifa</Link>, '0.3'),
    getItem(<Link to="cadastroRota">Cadastro Rota</Link>, '0.4'),
    getItem(<Link to="cadastroUsuario">Cadastro Usuário</Link>, '0.5'),
    getItem(<Link to="solicitacaoTransporteExtra">Solicitação Transporte Extra</Link>, '0.6'),
    getItem(<Link to="cadastroColaboradores">Cadastro Colaboradores</Link>, '0.7'),
    

    
    ]),
    getItem('Relatórios', 'sub1', <PieChartOutlined/>, [
     getItem(<Link to="ExtratoTransporteExtra">Extrato Transporte Extra</Link>, '5'),
      
    ]),
    getItem('Usuário', 'sub2', <TeamOutlined />, [
      getItem(<Button icon={<UserSwitchOutlined />} onClick={() => atualizarAcesso()}>Atualizar Acesso</Button>, '99'),
      getItem(<Link to="alterarSenha">Alterar Senha</Link>, '9.1'),
      getItem(<Link to="login">Login</Link>, '9', <LoginOutlined />),      
      getItem(<Link to="logout">Sair</Link>, '8', <LogoutOutlined />), 
      
    ]),
    getItem('', '10', <FileOutlined />),
  ];

const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
 function handlerSair(){
  fSair()
  window.location.reload();

 }
  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <div className="demo-logo-vertical" />
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer, height: '1px'}} >
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer, borderRadius: borderRadiusLG }}>
              <Routes>
                
                <Route path='/' element={<div style={{background: '#ffffff' }}><Image src='img/Andrade.jpg'></Image></div>} />         
                <Route path='/cadastroTipoVeiculo' element={<CadastroTipoVeiculo />} />
                <Route path='/cadastroMensagem' element={<CadastroMensagem />}/>
                <Route path='/CadastroTarifa' element={<CadastroTarifa />} />
                <Route path='/cadastroRota' element={<CadastroRota />} />
                <Route path='/cadastroUsuario' element={<CadastroUsuario />} />
                <Route path='/cadastroColaboradores' element={<CadastroColaboradores></CadastroColaboradores>}></Route>   
                <Route path='/extratoTransporteExtra' element={<ExtratoTransporteExtra />} /> 
                            
                <Route path='/solicitacaoTransporteExtra' element={<SolicitacaoTransporteExtra />} />


                <Route path='/logout' element={<Button type="primary" shape="round" onClick={() => handlerSair()} icon={<LogoutOutlined />} size={10} >Logout</Button>} />
                <Route path='/login' element={<Login />} />
                <Route path='/alterarSenha' element={<AlterarSenha />} />
                
                
              </Routes>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Cláudio Rocha ©{new Date().getFullYear()} - claudioperesrocha@gmail.com
          </Footer>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
