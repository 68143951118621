

import React, { useEffect } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Form,
  Input,
  Table,
  Popconfirm
} from 'antd';



import { 
  listar as listarRegistro, 
  excluir as excluirRegistro, 
  inserir as inserirRegistro, 
  atualizar as atualizarRegistro } from '../services/serviceMensagem'
  
const vBranco = { key: 0, idMensagem: 0, titulo: '',  mensagem: ''}
const vTextoDescricao = "Descrever mensagem"

const { TextArea } = Input;

const App = () => {


  const cancel = (e) => {
    console.log(e);
    message.error('Cancelada exclusão');
  };
  const columnsData = [

    {
      title: 'Id',
      dataIndex: 'key',
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handlerSelecionarRegistro(record.key)}></Button>

          <Popconfirm
            title="Excluir Registro"
            description="Você está certo que deseja excluir este registro?"
            onConfirm={() => handlerExcluir(record.key)}
            onCancel={cancel}
            okText="Sim"
            cancelText="Não"
          >
          <Button icon={<DeleteOutlined />} ></Button>
          </Popconfirm>
        </>
      )
    },

    {
      title: 'Titulo',
      dataIndex: 'titulo',
    },

    {
      title: 'Mensagem',
      dataIndex: 'mensagem',
    },

   

  ]



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setObj(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNumberChange = (value) => {
    setObj(prevState => ({
      ...prevState,
      valor: value
    }));
  };

  


  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = React.useState([])
  const [objEdit, setObj] = React.useState({ vBranco })

  async function callListar() {
    const vRetorno = await listarRegistro()


    if (vRetorno && vRetorno.status) {
      if (!vRetorno.status === 'erro') {
        setData(vRetorno)
      } else {
        messageApi.open({
          type: 'error',
          content: vRetorno.descricao,
        });
      }
    } else {
      setData(vRetorno)
    }
    
    console.log('retorno')
    console.log(vRetorno)
 

  
    //console.log(retorno)
  }



  useEffect(() => {
    // Coloque aqui o código que você quer executar logo após o app iniciar
    callListar()
    setObj(vBranco)
    console.log('O aplicativo foi iniciado!');
  }, []);


  function handleLimpar() {
    setObj(vBranco)
  }
  function handlerSelecionarRegistro(pId) {
    const objetoSelecionado = data.find(obj => obj.key === pId)
    setObj(objetoSelecionado)

  }

  async function handleCadastrar(pObj){
    let vRetorno
    console.log("Veiculo: " + pObj.key)

    if (pObj.key=== 0){
      vRetorno = await inserirRegistro(pObj);
      setObj(vBranco)
    }else{
      vRetorno = await atualizarRegistro(pObj);
      setObj(vBranco)
    }

    console.log("Retorno: " + vRetorno.status)
    
    if (vRetorno.status ==='Ok'){
      messageApi.open({
        type: 'success',
        content:  vRetorno.descricao,
      });
  
      callListar()

    }else{
      messageApi.open({
        type: 'error',
        content:  vRetorno.descricao,
      });
    }
  }

  async function handlerExcluir(pId) {
    const objetoSelecionado = data.find(obj => obj.key === pId)
    const vRetorno = await excluirRegistro(objetoSelecionado);
    //messageApi.info(vRetorno.descricao);
    //console.log('dddddd ' + vRetorno)
    if (vRetorno.status ==='Ok'){
      messageApi.open({
        type: 'success',
        content:  vRetorno.descricao,
      });
  
      callListar()

    }else{
      messageApi.open({
      type: 'error',
      content: vRetorno.descricao,
    });
  }
  }


  

  
  return (

    <>
      {contextHolder}
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        disabled={false}
        style={{ maxWidth: 600 }}
      >
        <Form.Item label="Titulo">
          <Input
            name="titulo"
            value={objEdit.titulo}
            onChange={handleInputChange}
          />
        </Form.Item>


        <Form.Item label="Menagem">
          <TextArea contextHolder={vTextoDescricao}
          name="mensagem"
            value={objEdit.mensagem}
            onChange={handleInputChange}
          />
        </Form.Item>
     


        
        <Form.Item label="">
          <Button onClick={() => handleCadastrar(objEdit)}>Cadastrar</Button>
          <Button onClick={handleLimpar}>Limpar</Button>
        </Form.Item>
      </Form>

      <Table dataSource={data} columns={columnsData}></Table>
    </>
  );



};


export default App;