import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { listarRotaSelect } from '../services/serviceRota';

let vRotasGlobal

export function allRotas(){

    return vRotasGlobal
}

const SelectRotas = ({ value, onChange, pvEmpresa }) => {
    const [vRotas, setRotas] = useState([]);

    useEffect(() => {
       
        async function atualizarRotas() {
            const vRetorno = await listarRotaSelect({idEmpresa: pvEmpresa})
            vRotasGlobal = vRetorno
            setRotas(vRetorno);
        }
        atualizarRotas()
        
    }, [pvEmpresa]);

    

    const handleChange = (value) => {
        onChange(value);
    };

    
    return (
        <Select
            value={value} // Define o valor selecionado no modo de edição
            onChange={handleChange}
        >
            {vRotas.map(option => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectRotas;

