import React, {useEffect, useState} from 'react';
import { LogoutOutlined,DesktopOutlined,  PieChartOutlined, TeamOutlined,  HomeOutlined, LoginOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Button,  Menu, Layout, theme, Image} from 'antd';
import SolicitacaoTransporteExtra from '../pages/solicitacaoTransporteExtraN';
import ExtratoTransporteExtra from '../pages/extratoTransporteExtraRH'
import CadastroUsuario from '../pages/cadastroUsuarioRH'
import Login from '../component/login'
import AlterarSenha from '../pages/alterarSenha'
import UploadColaboradores from '../pages/uploadColaboradores'

import CadastroColaboradores from '../pages/cadastroColaborador'

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
    logout as fSair,
    sessaoUsuarioBasico,
    atualizarAcesso
} from '../services/serviceLogin'
const { Header, Content, Footer, Sider } = Layout;
const App = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();


    const [vDisabledCC, setDisabledCC] = useState(false)


    function handlerSair() {
        fSair()
        window.location.reload()
    }
    useEffect(() => {
        const iniciar = async () => {
            const vRetorno = await sessaoUsuarioBasico()
            vRetorno.nivelAcesso === 'adm' || vRetorno.nivelAcesso === 'rh' ? setDisabledCC(false) : setDisabledCC(true)
        }
        
        iniciar()
    })

    const items = [
        getItem(<Link to="">Início</Link>, '00', <HomeOutlined />),
         getItem('Cadastros', '0', <DesktopOutlined />, 
         [
            getItem(<Link to="solicitacaoTransporteExtra">Solicitação Transporte Extra</Link>, '0.0'),
            getItem(<Link to="cadastroUsuario">Cadastro Usuário</Link>, '0.1'),
            getItem(<Link to="uploadColaboradores">Carga Colaboradores</Link>, '0.2'),
            getItem(<Link to="cadastroColaboradores">Cadastro Colaboradores</Link>, '0.3'),
            
         ]),

         getItem('Relatórios', '1', <PieChartOutlined/>, [
          getItem(<Link to="ExtratoTransporteExtra">Extrato Transporte Extra</Link>, '1.0'),
       
         ]),
         getItem('Usuário', '2', <TeamOutlined />, [
            getItem(<Button icon={<UserSwitchOutlined />} onClick={() => atualizarAcesso()}>Atualizar Acesso</Button>, '2.0'),
            getItem(<Link to="alterarSenha">Alterar Senha</Link>, '2.1'),
           getItem(<Link to="login">Login</Link>, '2.2', <LoginOutlined />),
           getItem(<Link to="logout">Sair</Link>, '2.3', <LogoutOutlined />), 
           
         ]),
         
       ];
       function getItem(label, key, icon, children) {
        return {
          key,
          icon,
          children,
          label,
        };
      }
      const [collapsed, setCollapsed] = useState(true);
    return (<>
        <Router>
        
        <Layout style={{ minHeight: '100vh' }}>
                <Header style={{ padding: 0, background: colorBgContainer, height: '1px' }} ></Header>
                <Layout>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />


                    </Sider>
                    <Content style={{ margin: '0 16px' }}>
                        {/* Link para a página de SolicitacaoTransporteExtra 
                        <Link to="/solicitacaoTransporteExtra">
                            <Button type="primary" shape="round" icon={<LogoutOutlined />} size="large"> Solicitação de Transporte Extra</Button>
                        </Link>
                    */}
                        {/* Botão de logout */}
                        {/*<Button type="primary" shape="round" onClick={handlerSair} icon={<LogoutOutlined />} size="large">Logout</Button>*/}

                        <br></br><br></br><br></br>
                        <div style={{ padding: 24, minHeight: 360, background: colorBgContainer, borderRadius: borderRadiusLG }}>
            <Routes>
                <Route path='/' element={<div style={{background: '#ffffff' }}><Image src='img/Andrade.jpg'></Image></div>} /> 
                <Route path='/solicitacaoTransporteExtra' element={<SolicitacaoTransporteExtra CCDisabled={vDisabledCC} />} />
                <Route path='/cadastroUsuario' element={<CadastroUsuario></CadastroUsuario>}></Route>
                <Route path='/cadastroColaboradores' element={<CadastroColaboradores></CadastroColaboradores>}></Route>
                
                <Route path='/uploadColaboradores' element={<UploadColaboradores></UploadColaboradores>}></Route>
                
                
                <Route path='/extratoTransporteExtra' element={<ExtratoTransporteExtra />} /> 
                <Route path='/logout' element={<Button type="primary" shape="round" onClick={() => handlerSair()} icon={<LogoutOutlined />} size={10} >Logout</Button>} />
                <Route path='/login' element={<Login />} />
                <Route path='/alterarSenha' element={<AlterarSenha />} />
            </Routes>
            </div>
                    </Content>

                </Layout>
                <Footer style={{ textAlign: 'center' }}>
                    Cláudio Rocha ©{new Date().getFullYear()} - claudioperesrocha@gmail.com
                </Footer>
            </Layout>


        </Router>
    </>)
}
export default App;