import React, { useEffect, useState } from 'react';

import { Form, Table, Space, Button, Select, DatePicker, Tag, Modal, Radio, Input, Popconfirm, message } from 'antd';
import { formatCurrency } from '../component/utils'


import { extratoAdm, excluirRota, inserirValorSugerido, selectRotasAdm, extradoDetalhamento, tarifaExtrato, inserirTarifaExtrato, excluirTarifaExtrato } from '../services/serviceExtrato'
import SelectEmpresa, { fLabelEmpresa } from '../component/selectEmpresa'
import {selecionarTarifa} from '../services/serviceTarifa'

const { RangePicker } = DatePicker;
const { TextArea } = Input;


const TabelaTransacoes = () => {
  const [form] = Form.useForm();
  const [dados, setDados] = useState([]);
  const [vDataIni, setDataIni] = useState('');
  const [vDataFim, setDataFim] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenDetalhamento, setIsModalOpenDetalhamento] = useState(false);
  const [isModalOpenTarifas, setIsModalOpenTarifas] = useState(false);
  const [vEmpresaSelecionada, setEmpresaSelecionada] = useState('');
  const [vOptionsRota, setOptionsRota] = useState([]);

  const [vTarifaSelecionada, setTarifaSelecionada] = useState([]);
  const [vValorTarifaSelecionada, setValorTarifaSelecionada] = useState([]);
  
  const [vOptionsTarifa, setOptionsTarifa] = useState([]);

  const [vDadosTarifa, setDadosTarifa] = useState([]);

  const [vDetalhamentoTransp, setDetalhamentoTransp] = useState('');

  const [vObjSelecionado, setObjSelecionado] = useState('');

  const [vAcao, setAcao] = useState(1);
  const [vObservacaoExtra, setObservacaoExtra]= useState();


  async function handlerDetalhamento(pObj) {
    const vRetorno = await extradoDetalhamento(pObj)
    setDetalhamentoTransp(vRetorno)
    setIsModalOpenDetalhamento(true)
  }


  const onChangeAcao = (e) => {
    console.log('radio checked', e.target.value);
    setAcao(e.target.value);
  };

  function showModal(pObjSelecionado) {
    //console.log(pObjSugerido)
    carregarOptionsRota(pObjSelecionado)
    setObjSelecionado(pObjSelecionado)
    setIsModalOpen(true);
  };

  function showModalTarifa(pObjSelecionado) {
    console.log(pObjSelecionado)
    carregarTarifa(pObjSelecionado)
    setObjSelecionado(pObjSelecionado)
    setIsModalOpenTarifas(true);
  };

  const handleExcluirRota = async () => {
    await excluirRota({ idCab_transp_extra: vObjSelecionado.idCab_transp_extra })
    buscarDados()
  }

  const handleInserirRota = async (pIdRota, pValor) => {
    console.log(pIdRota)
    console.log(pValor)
    console.log(vObjSelecionado.idCab_transp_extra)

    const vRetorno = await inserirValorSugerido({
      idCab_transp_extra: vObjSelecionado.idCab_transp_extra,
      idRotaSugestao: parseInt(pIdRota),
      valorSugerido: parseFloat(pValor)

    })
    //console.log('vRetorno')
    //console.log(vRetorno)
    buscarDados()
  }

  const handleOk = async () => {

    setIsModalOpen(false);

  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkTarifas = async () => {

    setIsModalOpenTarifas(false);

  };

  const handleCancelTarifas = () => {
    setIsModalOpenTarifas(false);
  };

  const handleOkDetalhamento = async () => {

    setIsModalOpenDetalhamento(false);

  };

  const handleCancelDetalhamento = () => {
    setIsModalOpenDetalhamento(false);
  };

  async function buscarDados() {
    const resultado = await extratoAdm({
      DataIni: vDataIni,
      DataFim: vDataFim,
      idEmpresa: vEmpresaSelecionada
    });
    //console.log(resultado)
    setDados(resultado);
  };

  async function carregarOptionsRota(oObj) {
    //console.log('oObj')
    //console.log(oObj)
    const vRetorno = await selectRotasAdm(oObj)
    console.log(vRetorno)
    setOptionsRota(vRetorno)
  }
function fTarifaSelecionada(e){
  const vRetorno = vOptionsTarifa.find(obj => obj.value === e)
  //console.log(vRetorno)
  setTarifaSelecionada(e)
  setValorTarifaSelecionada(vRetorno.valor)

}
  async function carregarTarifa(oObj) {
    //console.log('oObj')
    //console.log(oObj)
    const vRetorno = await tarifaExtrato(oObj)
    //console.log(vRetorno)
    setDadosTarifa(vRetorno)

    const vRetorno2 = await selecionarTarifa(oObj)
    //console.log('vRetorno2')
    //console.log(vRetorno2)
    setOptionsTarifa(vRetorno2)
  }


  useEffect(() => {


    //buscarDados();
  }, []);

  const cancel = (e) => {
   
    
    //console.log(e);
    message.error('Cancelada exclusão');
  };
async  function handlerExcluirTarifa(pObj){
  const vRetorno = await excluirTarifaExtrato(pObj)
  carregarTarifa(vObjSelecionado)
  buscarDados()

}

  const columnsTarifa = [
    { title: 'Id', dataIndex: 'idTarifaPorTransporte' },
    { title: 'Valor', dataIndex: 'valor',
    render: (data, record) =>{
      return(formatCurrency(data))
    }
    },
    { title: 'Observações', dataIndex: 'observacoes' },
    { title: 'Ação', 
    render: (data, record) =>{
      return(
        <Popconfirm
        title="Excluir Registro"
        description="Você está certo que deseja excluir este registro?"
        onConfirm={() => handlerExcluirTarifa(record)}
        onCancel={cancel}
        okText="Sim"
        cancelText="Não"
      >
      <Button>Excluir</Button>
      </Popconfirm>)
    }
    },

  ]
  const columnsRota = [
    { title: 'Id', dataIndex: 'idRota' },
    { title: 'Rota', dataIndex: 'rota' },
    {
      title: 'Valor', dataIndex: 'valor', render: (_, record) => {
        return (formatCurrency(parseFloat(record.valor)))
      }
    },

    {
      title: 'Rota Passageiro', dataIndex: 'idRotaPass',
      render: (_, record) => {
        const objetosFiltrados = vOptionsRota.filter(obj => obj.idRotaPass !== null);

        const arrayComValoresNumericos = objetosFiltrados.map(objeto => {
          return {
            ...objeto,
            valor: Number(objeto.valor) // ou usar +objeto.valor para converter para número
          };
        });
        //console.log('objetosFiltrados')
        //console.log(objetosFiltrados)
        const objetoMaiorValor = arrayComValoresNumericos.reduce((max, obj) => {
          return obj.valor > max.valor ? obj : max;

        }, arrayComValoresNumericos[0]);

        console.log(objetoMaiorValor)

        return (
          record.valor >= objetoMaiorValor.valor && record.idRotaPass !== null ? <><Tag color="gold">Pass</Tag><Tag color="green">Ok</Tag></> :
            record.idRotaPass !== null ? <Tag color="gold">Pass</Tag> : <></>



        )
      }
    },
    {
      title: 'Ação',
      render: (_, record) => {
        return (<Button onClick={() => handleInserirRota(record.idRota, record.valor)}>Aprovar Rota</Button>)
      }
    },
  ]

  const columns = [
    { title: 'Id', dataIndex: 'idCab_transp_extra' },
    { title: 'Solicitante', dataIndex: 'nome' },
    { title: 'Centro de Custos', dataIndex: 'centroCusto' },
    { title: 'R$ Transporte', dataIndex: 'valorTransporte', render: (_, record) => { return (formatCurrency(record.valorTransporte)) } },
    //{ title: 'Vlr Rota', dataIndex: 'valorRota', render: (_, record) => { return (formatCurrency(record.valorRota)) } },
    { title: 'R$ Rota', dataIndex: 'valorSugerido', render: (_, record) => { return (formatCurrency(record.valorSugerido)) } },
    { title: 'R$ Extras', dataIndex: 'valorExtra', render: (_, record) => { return (formatCurrency(record.valorExtra)) } },
    {
      title: 'R$ Total', render: (_, record) => {
        return (
          parseFloat(record.valorRota) === 0 ?
            formatCurrency(parseFloat(record.valorExtra) + parseFloat(record.valorSugerido)) :
            formatCurrency(parseFloat(record.valorExtra) + parseFloat(record.valorRota)))
      }
    },
    {
      title: '', render: (_, record) => {
        return (

          parseFloat(record.valorTransporte) !== 0 && record.valorTransporte !== null ? <Tag color="green">Ok</Tag> :
            parseFloat(record.valorRota) !== 0 ? <Tag color="gold">Confirmar</Tag> :
              parseFloat(record.valorRota) === 0 && parseFloat(record.valorSugerido) !== 0 ? <Tag color="red">Aguardando</Tag> : 0

        )
      }
    },
    {
      title: '', render: (_, record) => {

        return (
          <>
            <Button onClick={() => showModal(record)}>Aprovar Rota</Button>
            <Button onClick={() => showModalTarifa(record)}>Aprovar Tarifa</Button>
            <Button onClick={() => handlerDetalhamento(record)}>Detalhamento</Button>
          </>

        )
      }
    },

  ];


  const handleEmpresaChange = (value) => {
    //const vRetorno = objTarifa
    //vRetorno.idEmpresa = value
    //setObjTarifa(vRetorno)
    setEmpresaSelecionada(value);
  };
  const onSelectedEmpresa = (e) => {
    setEmpresaSelecionada(e)
    //console.log(e)
  }

  const onChangeData = (date, dateString) => {
    //console.log(date[0].format('YYYY-MM-DD'))
    //console.log(date[1].format('YYYY-MM-DD'))
    if (date !== null) {
      setDataIni(date[0].format('YYYY-MM-DD'))
      setDataFim(date[1].format('YYYY-MM-DD'))
    } else {
      setDataIni('')
      setDataFim('')
    }


    //console.log(vDataIni)
    //console.log(vDataFim)

  };
  
  async function handlerInserirTarifa(){
    console.log('handlerInserirTarifa')
    const vRetorno = await inserirTarifaExtrato({
                                idCab_transp_extra: vObjSelecionado.idCab_transp_extra,
                                valor: vValorTarifaSelecionada,
                                idTarifa: vTarifaSelecionada,
                                observacoes: vObservacaoExtra
                              })
                              console.log('vRetorno zzzzz')
                              console.log(vRetorno)
                              

                              carregarTarifa(vObjSelecionado)
                              buscarDados()

  }

  return (
    <>
      <Form form={form} component={false}>
        <Space direction="horizontal" size={12}>

          <Form.Item label="Data Transporte">
            <RangePicker onChange={onChangeData} format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item label="Empresa">
            <SelectEmpresa value={vEmpresaSelecionada} onChange={handleEmpresaChange}></SelectEmpresa>

          </Form.Item>
          <Form.Item label="">
            <Button onClick={buscarDados}>Consultar</Button>
          </Form.Item>
        </Space>
        <br></br>

        <Table dataSource={dados} pagination={false} scroll={{
          y: 640,
        }} columns={columns} />

      </Form>

      <Modal title="Definir valor a ser utilizado" open={false} >
        <Radio.Group onChange={onChangeAcao} value={vAcao}>
          <Space direction="vertical">
            <Radio value={1}>Excluir valor Atual - <b>Rota:</b> {vObjSelecionado.rota} <b>Valor:</b> {formatCurrency(parseFloat(vObjSelecionado.valorTransporte))}</Radio>
            <Radio disabled={vObjSelecionado.idRotaSugestao === 0 ? true : false} value={2}><b>Valor maior Rota </b><br></br>
              {vObjSelecionado.idRotaSugestao} - {vObjSelecionado.rotaSugestao} - {formatCurrency(parseFloat(vObjSelecionado.valorSugerido))}<br></br>
            </Radio>

            <Radio value={3}>
              <Select style={{
                width: 320,
              }}
                options={vOptionsRota}
              >

              </Select>

            </Radio>
            <Radio value={4}>

              {vAcao === 4 ? (
                <Form>
                  <Form.Item label="Valor">
                    <Input
                      style={{
                        width: 100,
                        marginLeft: 10,
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Justificativa">
                    <TextArea rows={4} />
                  </Form.Item>
                </Form>

              ) : 'Outros valores...'}
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>

      <Modal title="Definir valor a ser utilizado" width={1000} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

        Excluir valor Atual: <br></br>
        <b>Id Rota:</b> {vObjSelecionado.idRota} <br></br>
        <b>Rota:</b> {vObjSelecionado.rota} <br></br>
        <b>Valor:</b> {formatCurrency(parseFloat(vObjSelecionado.valorTransporte))}<br></br>
        <Button onClick={handleExcluirRota}>Excluir</Button>

        <Table pagination={false} dataSource={vOptionsRota} columns={columnsRota}></Table>
      </Modal>


      <Modal title="Tarifas" width={1000} open={isModalOpenTarifas} onOk={handleOkTarifas} onCancel={handleCancelTarifas}>
        <Select 
        onChange={fTarifaSelecionada}
        style={{
                
                width: 300,
              }} 
              options={vOptionsTarifa}></Select><br></br>
        Valor: {formatCurrency(vValorTarifaSelecionada)}
        <TextArea value={vObservacaoExtra} onChange={(e) =>{setObservacaoExtra(e.target.value)}}></TextArea>
        <Button onClick={handlerInserirTarifa}>Inserir</Button>
        <Table pagination={false} dataSource={vDadosTarifa} columns={columnsTarifa}></Table>
      </Modal>


      <Modal title="Detalhamento" width={1000} open={isModalOpenDetalhamento} onOk={handleOkDetalhamento} onCancel={handleCancelDetalhamento}>
        <br></br>
        <b>Cabeçalho solicitação de transporte</b>
        <Table pagination={false} dataSource={vDetalhamentoTransp[0]}
          columns={[
            { title: 'Id', dataIndex: 'idCab_transp_extra' },
            {
              title: 'Valor', dataIndex: 'valorTransporte', render: (_, record) => {
                return (formatCurrency(parseFloat(record.valorTransporte)))
              }
            },
            {
              title: 'Data Transporte', dataIndex: 'dataTransporte', render: (_, record) => {
                const vDataSolicitacao = new Date(record.dataTransporte)

                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }; // Exemplo de opções
                const formattedDate = vDataSolicitacao.toLocaleDateString('pt-BR', options);
                return (formattedDate)
              }
            },

            {
              title: 'Data Cadastro', dataIndex: 'dataCadastro', render: (value, record) => {
                const vDataSolicitacao = new Date(value)

                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }; // Exemplo de opções
                const formattedDate = vDataSolicitacao.toLocaleDateString('pt-BR', options);
                return (formattedDate)
              }
            },
          ]}></Table>

        <br></br>
        <br></br>
        <br></br>
        <b>Passageiros solicitação de transporte</b>
        <Table pagination={false} dataSource={vDetalhamentoTransp[1]}
          columns={[
            { title: 'Id', dataIndex: 'idCab_transp_extra' },
            { title: 'Nome', dataIndex: 'nomePassageiro' },
            { title: 'Endereço', dataIndex: 'endereco' },
          ]}></Table>

        <br></br>
        <br></br>
        <br></br>
        <b>Tarifas</b>
        <Table pagination={false} dataSource={vDetalhamentoTransp[2]}
          columns={[
            { title: 'Id', dataIndex: 'idCab_transp_extra' },
            { title: 'Valor', dataIndex: 'valor' ,
            render: (data,recor) => {
              return(formatCurrency(data))
            }
            },
            { title: 'Observações', dataIndex: 'observacoes' },
          ]}></Table>
      </Modal>


    </>
  );

};

export default TabelaTransacoes;
