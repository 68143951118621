

import React, { useEffect } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Form,
  Input,
  InputNumber,
  Table,
  Popconfirm
} from 'antd';


import { listarVeiculos, excluirVeiculos, inserirVeiculo, atualizarVeiculo } from '../services/serviceVeiculo'

const { TextArea } = Input;

const vVeiculoBranco = { tipoVeiculo: '', key: 0, qtdLugares: '', descricao: '', idVeiculo: 0 }




const FormVeiculo = () => {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setObjVeiculo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNumberChange = (value) => {
    setObjVeiculo(prevState => ({
      ...prevState,
      qtdLugares: value
    }));
  };

  const cancel = (e) => {
    console.log(e);
    message.error('Cancelada exclusão');
  };

  const columnsData = [

    {
      title: 'Id',
      dataIndex: 'idVeiculo',
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handlerVeiculo(record.idVeiculo)}></Button>

          <Popconfirm
            title="Excluir Registro"
            description="Você está certo que deseja excluir este registro?"
            onConfirm={() => handlerExcluirVeiculo(record.idVeiculo)}
            onCancel={cancel}
            okText="Sim"
            cancelText="Não"
          >
          <Button icon={<DeleteOutlined />} ></Button>
          </Popconfirm>
        </>
      )
    },

    {
      title: 'Tipo',
      dataIndex: 'tipoVeiculo',
    },

    {
      title: 'Qtd. Lugares',
      dataIndex: 'qtdLugares',
    },

    {
      title: 'Descrição',
      dataIndex: 'descricao',
    },

  ]


  const [messageApi, contextHolder] = message.useMessage();
  function handleLimpar() {
    setObjVeiculo(vVeiculoBranco)
  }
  function handlerVeiculo(pId) {
    const objetoSelecionado = data.find(obj => obj.idVeiculo === pId)
    setObjVeiculo(objetoSelecionado)

  }

  async function handleCadastrar(pVeiculo) {
    let vRetorno
    console.log("Veiculo: " + pVeiculo.idVeiculo)

    if (pVeiculo.idVeiculo === 0) {
      vRetorno = await inserirVeiculo(pVeiculo);
      setObjVeiculo(vVeiculoBranco)
    } else {
      vRetorno = await atualizarVeiculo(pVeiculo);
      setObjVeiculo(vVeiculoBranco)
    }

    console.log("Retorno: " + vRetorno.status)

    if (vRetorno.status === 'Ok') {
      messageApi.open({
        type: 'success',
        content: vRetorno.descricao,
      });

      callListarVeiculos()

    } else {
      messageApi.open({
        type: 'error',
        content: vRetorno.descricao,
      });
    }
  }

  async function handlerExcluirVeiculo(pId) {
    const objetoSelecionado = data.find(obj => obj.idVeiculo === pId)
    const vRetorno = await excluirVeiculos(objetoSelecionado);
    /*
     console.log('Excluir vRetorno')
     console.log(vRetorno)
     */
    if (vRetorno.status === 'Ok') {
      messageApi.open({
        type: 'success',
        content: vRetorno.descricao,
      });

      callListarVeiculos()

    } else {
      messageApi.open({
        type: 'error',
        content: vRetorno.descricao,
      });
    }




  }


  const [data, setData] = React.useState([])
  const [objVeiculo, setObjVeiculo] = React.useState({ vVeiculoBranco })

  async function callListarVeiculos() {
    const vRetorno = await listarVeiculos()

    if (vRetorno && vRetorno.status) {
      if (!vRetorno.status === 'erro') {
        setData(vRetorno)
      } else {
        messageApi.open({
          type: 'error',
          content: vRetorno.descricao,
        });
      }
    } else {
      setData(vRetorno)
    }
    
    console.log('retorno')
    console.log(vRetorno)
  }



  useEffect(() => {
    // Coloque aqui o código que você quer executar logo após o app iniciar
    callListarVeiculos()
    setObjVeiculo(vVeiculoBranco)
    console.log('O aplicativo foi iniciado!');
  }, []);


  return (

    <>
      {contextHolder}
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        disabled={false}
        style={{ maxWidth: 600 }}
      >
        <Form.Item label="Tipo Veículo">
          <Input
            name="tipoVeiculo"
            value={objVeiculo.tipoVeiculo}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item label="Qtd. Lugares">
          <InputNumber
            value={objVeiculo.qtdLugares}
            onChange={handleNumberChange}
          />
        </Form.Item>

        <Form.Item label="Descrição">
          <TextArea
            name="descricao"
            value={objVeiculo.descricao}
            rows={4}
            placeholder="Descrever detalhes do veículos"
            onChange={handleInputChange}
          />
        </Form.Item>


        <Form.Item label="">
          <Button onClick={() => handleCadastrar(objVeiculo)}>Cadastrar</Button>
          <Button onClick={handleLimpar}>Limpar</Button>
        </Form.Item>
      </Form>

      <Table dataSource={data} columns={columnsData}></Table>
    </>
  );



};


export default FormVeiculo;