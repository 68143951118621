


    export function formatNumber(value){
        return new Intl.NumberFormat('pt-BR').format(value)

    }

    export function formatCurrency(value){

        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    }

    export function formatDate(value){
        return new Intl.DateTimeFormat('pt-BR').format(value)

    }

    export function mensAcessoNegado(value){
        return 'Acesso negado! ' + value

    }

    export function validarErro(pObj, pNomeAplicacao){
        // Verifica se o erro tem uma resposta (ou seja, foi um erro de HTTP)
        if (pObj) {
            console.log('Erro no serviço: ', pObj.status);

            // Você pode tratar diferentes status de erro aqui, se necessário
            if (pObj.status === 403) {
                // Tratamento específico para status 403
                const vMensagem = { descricao: mensAcessoNegado(pNomeAplicacao + ' (403 - Erro)'), status: 'erro' }
                console.log(vMensagem)
                return vMensagem; // ou throw error;
            }

            if (pObj.status === 401) {
                // Tratamento específico para status 401
                const vMensagem = { descricao: mensAcessoNegado(pNomeAplicacao + ' (401 - Erro)'), status: 'erro' }
                return vMensagem; // ou throw error;
            }

            if (pObj.status === 500) {
                // Tratamento específico para status 401
                const vMensagem = { descricao: pObj.data.descricao + ' (500 - Erro)', status: 'erro' }
                //console.log(pObj.data.descricao)
                return vMensagem; // ou throw error;
            }

            // Retorna ou lança o erro, dependendo da sua necessidade
            return 'null'; // ou throw error;
        } else {
            // Para erros que não são de HTTP
            console.log('Erro ao fazer a chamada HTTP:');
            return null; // ou throw error;
        }
    }

    

//export const fUrlAPI = () => {return(window.location.protocol + '//' + window.location.hostname + ':3100')}

export const fUrlAPI = () => {
    return window.location.protocol === 'http:' ?
        'http://' + window.location.hostname + ':3100' :
        'https://' + window.location.hostname + '/api';
};

export const urlAPI = fUrlAPI()
    //export const urlAPI = 'http://10.8.28.114:3100';
    //export const urlAPI = 'http://localhost:3100';
    //export const urlAPI = 'http://192.168.100.84:3100';
    //export const urlAPI = 'http://172.20.0.209:3100';
    //export const urlAPI = 'http://10.8.28.118:3100';
    //export const urlAPI = 'http://168.195.87.136:3100';

