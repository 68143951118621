
import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';
const vNomeAplicacao = "Rota"

// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;



export async function listarRota() {
    try {
        const response = await axios.get(urlAPI + '/listarRota')
        //console.log('Dados service: ', response.status)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}

export async function listarRotaSelect(pObj) {
    console.log(pObj)
    try {
        const response = await axios.post(urlAPI + '/listarRotaSelect',pObj)
        //console.log('Dados service: ', response.status)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}

export async function excluirRota(pRota) {
    try {
        const response = await axios.post(urlAPI + '/excluirRota', pRota)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}
export async function inserirRota(pRota) {
    try {
        const response = await axios.post(urlAPI + '/inserirRota', pRota)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}

export async function atualizarRota(pRota) {
    try {
        const response = await axios.post(urlAPI + '/atualizarRota', pRota)
        //console.log('Dados service: ', response.data)
        return response.data;

    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}




