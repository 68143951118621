
import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';
const vNomeAplicacao = 'Tipo de Veículo'
// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;

export async function listarVeiculos() {
    try {
        const response = await axios.get(urlAPI + '/listarVeiculos')
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }


}

export async function listarVeiculosSelect() {
    try {
        const response = await axios.get(urlAPI + '/listarVeiculosSelect')
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }


}

export async function excluirVeiculos(pVeiculo) {
try{
    const response = await axios.post(urlAPI + '/excluirVeiculo', pVeiculo)
    //console.log('Dados service: ', response.data)
    return response.data;
} catch (error) {

    return validarErro(error.response, vNomeAplicacao)

}


}
export async function inserirVeiculo(pVeiculo) {

    const response = await axios.post(urlAPI + '/inserirVeiculo', pVeiculo)
    //console.log('Dados service: ', response.data)
    return response.data;


}

export async function atualizarVeiculo(pVeiculo) {
    try {
        const response = await axios.post(urlAPI + '/atualizarVeiculo', pVeiculo)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }


}




