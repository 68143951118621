
import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';

const vNomeAplicacao = "Colaborador"
/* Rotas do servidor */
const vRotas = {
    filtrarColaboradores: '/filtrarColaboradores',
    listarColaboradores: '/listarColaboradores',
    inserirColaborador: '/inserirColaborador',
    atualizarColaborador: "/atualizarColaborador"
    
}

// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;

export async function filtrarColaboradores(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.filtrarColaboradores, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function listarColaboradores(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.listarColaboradores, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function inserirColaborador(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.inserirColaborador, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function atualizarColaborador(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.atualizarColaborador, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}
