
import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';

const vNomeAplicacao = "Usuario"
/* Rotas do servidor */
const vRotas = {
    listar: '/listarUsuario',
    inserir: '/inserirUsuario',
    atualizar: '/atualizarUsuario',
    excluir: '/excluirUsuario',
    listarUsuarioIdEmpresa: '/listarUsuarioIdEmpresa',
    resetSenha: '/resetSenha',
    atualizarSenhaHash: '/atualizarSenhaHash',
    atualizarSenha: '/atualizarSenha'
}

// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;

export async function listarUsuarioIdEmpresa(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.listarUsuarioIdEmpresa, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }


}

export async function resetSenha(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.resetSenha, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function atualizarSenha(pObj) {
    console.log('atualizarSenha ' + urlAPI + vRotas.atualizarSenha)
    try {
        const response = await axios.post(urlAPI + vRotas.atualizarSenha, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function atualizarSenhaHash(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.atualizarSenhaHash, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }


}

export async function listar() {
    try {
        const response = await axios.get(urlAPI + vRotas.listar);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}


export async function excluir(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.excluir, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }


}
export async function inserir(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.inserir, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}

export async function atualizar(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.atualizar, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;

    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}




