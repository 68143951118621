import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';
const vNomeAplicacao = 'Transporte Extra'
// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;

export async function inserirSolicitacaoTranspExtra(pSolicitacao) {
    try {
        const response = await axios.post(urlAPI + '/inserirSolicitacaoTranspExtra', pSolicitacao)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function selecionarSolicitacaoTrasporteExtra(pSolicitacao) {
    try {
        const response = await axios.post(urlAPI + '/selecionarSolicitacaoTrasporteExtra', pSolicitacao)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}