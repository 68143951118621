

import React, { useEffect } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Form,
  Input,
  InputNumber,
  Table,
  Popconfirm
} from 'antd';

import {
  listarRota as listarRegistro,
  excluirRota as excluirRegistro,
  inserirRota as inserirRegistro,
  atualizarRota as atualizarRegistro
} from '../services/serviceRota'

import SelectEmpresa, {fLabelEmpresa} from '../component/selectEmpresa'
import { formatCurrency } from '../component/utils'

const vBranco = { key: 0, idRota: 0, rota: '', valor: '', distancia: '0', descricao: '' }
const vTextoDescricao = "Descrever detalhes da rota"

const { TextArea } = Input;

const App = () => {

  const cancel = (e) => {
    console.log(e);
    message.error('Cancelada exclusão');
  };

  const columnsData = [

    {
      title: 'Id',
      dataIndex: 'key',
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handlerSelecionarRegistro(record.key)}></Button>
          <Popconfirm
            title="Excluir Registro"
            description="Você está certo que deseja excluir este registro?"
            onConfirm={() => handlerExcluir(record.key)}
            onCancel={cancel}
            okText="Sim"
            cancelText="Não"
          >
          <Button icon={<DeleteOutlined />} ></Button>
          </Popconfirm>
        </>
      )
    },

    {
      title: 'Rota',
      dataIndex: 'rota',
    },

    {
      title: 'Valor',
      dataIndex: 'valor',
      render: (value,record) =>{return(<>{formatCurrency(parseFloat(value))}</>)}
    },

    {
      title: 'Distância',
      dataIndex: 'distancia',
    },

    {
      title: 'Descrição',
      dataIndex: 'descricao',
    },
    {
      title: 'Empresa',
      dataIndex: 'idEmpresa',
      render: (_, record)=>{ return(<>{fLabelEmpresa(record.idEmpresa)}</>)},
      
    },

  ]



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setObj(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNumberChange = (value) => {
    setObj(prevState => ({
      ...prevState,
      valor: value
    }));
  };




  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = React.useState([])
  const [objEdit, setObj] = React.useState({ vBranco })
  const [vEmpresaSelecionada,setEmpresaSelecionada] =  React.useState()

  const handleEmpresaChange = (value) => {
    const vRetorno = objEdit
    vRetorno.idEmpresa = value
    //setObjTarifa(vRetorno)
    setEmpresaSelecionada(value);
  };

  async function callListar() {
    const vRetorno = await listarRegistro()
    if (vRetorno && vRetorno.status) {
      if (!vRetorno.status === 'erro') {
        setData(vRetorno)
      } else {
        messageApi.open({
          type: 'error',
          content: vRetorno.descricao,
        });
      }
    } else {
      setData(vRetorno)
    }

    console.log('retorno')
    console.log(vRetorno)

  }




  useEffect(() => {
    // Coloque aqui o código que você quer executar logo após o app iniciar
    callListar()
    setObj(vBranco)
    console.log('O aplicativo foi iniciado!');
  }, []);


  function handleLimpar() {
    setObj(vBranco)
    setEmpresaSelecionada()
  }
  function handlerSelecionarRegistro(pId) {
    const objetoSelecionado = data.find(obj => obj.key === pId)
    setObj(objetoSelecionado)
    setEmpresaSelecionada(objetoSelecionado.idEmpresa)

  }

  async function handleCadastrar(pObj) {
    let vRetorno
    console.log("Rota: " + pObj.key)
    console.log(pObj)
    pObj.idEmpresa = vEmpresaSelecionada
    pObj.valor = pObj.valor.replace(',', '.')
    console.log(pObj)
    

    if (pObj.key === 0) {
      vRetorno = await inserirRegistro(pObj);
      setObj(vBranco)
      setEmpresaSelecionada()
    } else {
      vRetorno = await atualizarRegistro(pObj);
      setObj(vBranco)
      setEmpresaSelecionada()
    }

    console.log("Retorno: " + vRetorno.status)

    if (vRetorno.status === 'Ok') {
      messageApi.open({
        type: 'success',
        content: vRetorno.descricao,
      });

      callListar()

    } else {
      messageApi.open({
        type: 'error',
        content: vRetorno.descricao,
      });
    }
  }

  async function handlerExcluir(pId) {
    const objetoSelecionado = data.find(obj => obj.key === pId)
    const vRetorno = await excluirRegistro(objetoSelecionado);
    //messageApi.info(vRetorno.descricao);
    //console.log('dddddd ' + vRetorno)
    if (vRetorno.status === 'Ok') {
      messageApi.open({
        type: 'success',
        content: vRetorno.descricao,
      });

      callListar()

    } else {
      messageApi.open({
        type: 'error',
        content: vRetorno.descricao,
      });
    }


  }





  return (

    <>
      {contextHolder}
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        disabled={false}
        style={{ maxWidth: 600 }}
      >
        <Form.Item label="Empresa">
            <SelectEmpresa value={vEmpresaSelecionada} onChange={handleEmpresaChange}></SelectEmpresa>

        </Form.Item>

        <Form.Item label="Rota">
          <Input
            name="rota"
            value={objEdit.rota}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item label="Valor">
          <Input
            name = "valor"
            value={objEdit.valor}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item label="Distância">
          <Input
            name="distancia"
            value={objEdit.distancia}
            onChange={handleInputChange}
          />
        </Form.Item>


        <Form.Item label="Descrição">
          <TextArea
            name="descricao"
            value={objEdit.descricao}
            rows={4}
            placeholder={vTextoDescricao}
            onChange={handleInputChange}
          />
        </Form.Item>


        <Form.Item label="">
          <Button onClick={() => handleCadastrar(objEdit)}>Cadastrar</Button>
          <Button onClick={handleLimpar}>Limpar</Button>
        </Form.Item>
      </Form>

      <Table dataSource={data} columns={columnsData}></Table>
    </>
  );



};


export default App;