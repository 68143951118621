import React, { useEffect, useState, version } from 'react';
import { Form, Space, DatePicker, Input, Select, Table, Button, Modal, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

//import { formatCurrency } from '../component/utils'
import SelectEmpresa from '../component/selectEmpresa'
import { sessaoUsuario } from '../services/serviceLogin'
import { listarVeiculosSelect } from '../services/serviceVeiculo'


import { filtrarColaboradores } from '../services/serviceColaborador';
import { inserirSolicitacaoTranspExtra } from '../services/serviceTransporteExtra'
import CompSelectRotas from '../component/compSelectRotas'

const { TextArea } = Input;

const SolicTransporteExtra = (props) => {
    //const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const [vEmpresaSelecionada, setEmpresaSelecionada] = useState('');
    const [vDataTransp, setDataSaida] = useState('');
    const [vCentroCusto, setCentroCusto] = useState('');
    const [vNomeUsuario, setNomeUsuario] = useState();
    const [vIdUsuarios, setIdUsuario] = useState();
    const [vLiberarSelectEmpresa, setLiberarSelectEmpresa] = useState();
    const [vObservacoes, setObservacoes] = useState('');
    const [vTipoVeiculo, setTipoVeiculo] = useState();
    const [vDefaultValueVeiculo, setDefaultValueVeiculo] = useState()
    const [vIdVeiculo, setIdVeiculo] = useState();
    const [vQtdLugares, setQtdLugares] = useState();
    const [vPassageiros, setPassageiros] = useState();
    const [isModalOpenPassageiro, setIsModalOpenPassageiro] = useState(false);
    const [isModalOpenColaborador, setIsModalOpenColaborador] = useState(false);
    const [tabColaboradores, setTabColaboradores] = useState([])



    const [idPassageiroEdit, setIdPassageiroEdit] = useState();


    const { CCDisabled } = props;

    const iniciar = async () => {
        console.log('O aplicativo foi iniciado!');
        const vUsuario = await sessaoUsuario();
        setCentroCusto(vUsuario.centroCusto);
        setNomeUsuario(vUsuario.nome)
        setIdUsuario(vUsuario.idUsuario)
        //setIdEmpresa(vUsuario.idEmpresa)
        setEmpresaSelecionada(vUsuario.idEmpresa);
        //setNivelAcesso(vUsuario.nivelAcesso)
        setLiberarSelectEmpresa(vUsuario.nivelAcesso === 'adm' ? false : true)

        const vRetornoVeiculos = await listarVeiculosSelect()
        setTipoVeiculo(vRetornoVeiculos)

    }
    useEffect(() => {
        iniciar()
    }, []);


    const handleChangeTipoVeiculo = (value) => {
        //setValorSelecionado(value);
        const objetoSelecionado = vTipoVeiculo.find(obj => obj.value === value)
        setQtdLugares(objetoSelecionado.qtdLugares)
        setIdVeiculo(objetoSelecionado.value)


        fCarregarPassageiros(objetoSelecionado)

        //gridPassageiros()
        //console.log('eeee ' + valorSelecionado)
        //console.log(`Valor selecionado: ${value}`);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setObservacoes(value)



    };

    const handleEmpresaChange = (value) => {
        setEmpresaSelecionada(value);
    };

    const onChangeData = (date, dateString) => {


        //console.log(date.format('YYYY-MM-DD HH:mm:ss'));

        if (date !== null) {
            setDataSaida(date.format('YYYY-MM-DD HH:mm:ss'))
        } else {
            setDataSaida('')
        }


    };

    const [vEnderecoPass, setEnderecoPass] = useState();
    const [vEnderecoDestino, setEnderecoDestino] = useState();
    const [vObservacaoPass, setObservacaoPass] = useState();
    const [vRotaPass, setRotaPass] = useState();
    const [vNomePass, setNomePass] = useState();
    const [vKeyPass, setKeyPass] = useState();
    const [vTextoLocalizar, setTextoLocalizar] = useState();
    

    const fEditar = (pObj) => {

        setIdPassageiroEdit(pObj.key)
        setEnderecoPass(pObj.endereco)
        setRotaPass(pObj.idRota)
        setObservacaoPass(pObj.observacoes)
        setNomePass(pObj.nomePassageiro)
        setKeyPass(pObj.key)


        setIsModalOpenPassageiro(true)

    }

    const columnsPassageiros = [
        {
            title: 'Ação',
            width: '15%',
            render: (_, record) => {
                return (<><Button onClick={() => fEditar(record)}>Editar</Button></>)
            }

        },
        {
            title: 'Passageiro',
            dataIndex: 'nomePassageiro',
            width: '15%',

        },
        {
            title: 'Rota',
            dataIndex: 'idRota',
            width: '30%',

        },
        {
            title: 'Endereco',
            dataIndex: 'endereco',
            width: '30%',

        },

        {
            title: 'Observações',
            dataIndex: 'observacoes',
            width: '30%',
        }
    ]


    const fCarregarPassageiros = (pObj) => {
        // console.log('fCarregarPassageiros')

        const vPass = []
        //console.log(pObj.qtdLugares)
        for (let i = 0; i < pObj.qtdLugares; i++) {
            console.log(i)
            vPass.push({
                key: i,
                nomePassageiro: ``,
                idRota: ``,
                endereco: ``,
                observacoes: ``
            });
        }

        //console.log(vPass)

        setPassageiros(vPass)
    }

    const handleOkPassageiro = async () => {
        const vIndex = vPassageiros.findIndex(obj => obj.key === vKeyPass)

        vPassageiros[vIndex].nomePassageiro = vNomePass
        vPassageiros[vIndex].observacoes = vObservacaoPass
        vPassageiros[vIndex].endereco = vEnderecoPass
        vPassageiros[vIndex].idRota = vRotaPass
        vPassageiros[vIndex].enderecoDestino = vEnderecoDestino


        setPassageiros(vPassageiros)

        setIsModalOpenPassageiro(false);

    };

    const handleCancelPassageiro = () => {
        setIsModalOpenPassageiro(false);
    };

    async function fLimparForm() {
        console.log('fLimparForm')

        setPassageiros([])
        setDataSaida('')
        setObservacoes('')
        setIdVeiculo('')
    }

    function fVerificarPassageiros() {
        if (vPassageiros) {
            const vValidacao = vPassageiros.some(objeto => objeto.nomePassageiro && objeto.endereco && objeto.idRota);
            return vValidacao
        } else {
            return false
        }
    }

    function fAbrirFiltroColaboradores(){
        setIsModalOpenColaborador(true)

    }

    async function fFiltrarColaboradores(){
        console.log(vTextoLocalizar)
        const vRetorno = await filtrarColaboradores({Nome: vTextoLocalizar})
        setTabColaboradores (vRetorno)
        console.log(vRetorno)
        //setIsModalOpenColaborador(true)
    }

    async function fAtualizarCadastro() {
        if (fVerificarPassageiros()) {
            let vCabecalho = {
                idEmpresa: vEmpresaSelecionada,
                idUsuario: vIdUsuarios,
                centroCusto: vCentroCusto,
                idVeiculo: vIdVeiculo,
                dataTransporte: vDataTransp,
                hora: '',
                observacoes: vObservacoes
            };
            let vEnviar = [vCabecalho, vPassageiros]; // Inclui cabeçalho e passageiros

            console.log(vEnviar)

            const vRetorno = await inserirSolicitacaoTranspExtra(vEnviar)
            //console.log('vRetorno')
            //console.log(vRetorno)
            if ((vRetorno && vRetorno.status === 'Ok') || (vRetorno && vRetorno.success)) {
                messageApi.open({
                    type: 'success',
                    content: vRetorno.descricao,
                });
                //console.log('fLimparForm()')
                await fLimparForm()
            } else {
                messageApi.open({
                    type: 'error',
                    content: vRetorno.descricao,
                });
            }
        } else {
            messageApi.open({
                type: 'error',
                content: 'Nenhum passageiro cadastrado',
            });
        }
    }
    const fFecharModalColaborador =  () => {
        setIsModalOpenColaborador(false)

    }

    const fSetColaboradores = (record) =>{
        setNomePass(record.nome)
        setEnderecoPass(
            record.bairro + ', ' +
            record.endereco + ', ' +
            record.numero + ', ' +
            record.estado + ' - ' +
            record.cidade
        )
        setObservacaoPass(record.observacoes)
    }


    const fSetColaboradoresDestino = (record) =>{
        //setNomePass(record.nome)
        setEnderecoDestino(
            record.bairro + ', ' +
            record.endereco + ', ' +
            record.numero + ', ' +
            record.estado + ' - ' +
            record.cidade
        )
        //setObservacaoPass(record.observacoes)
    }



    return (

        <>
            {contextHolder}
            <b>Solicitante:</b> {vNomeUsuario}  <br></br><br></br>


            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}

                autoComplete="off"
            >
                <Space direction="horizontal" size={12}></Space>
                <Form.Item label="Centro de Custos">
                    <Input disabled={CCDisabled} style={{ maxWidth: 150 }} onChange={(e) => setCentroCusto(e.target.value)} value={vCentroCusto} />
                </Form.Item>

                <Form.Item label="Empresa">
                    <SelectEmpresa value={vEmpresaSelecionada} disabled={vLiberarSelectEmpresa} onChange={handleEmpresaChange}></SelectEmpresa>
                </Form.Item>
                <Form.Item label="Tipo Veículo" name="lblTipoVeículo"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Select options={vTipoVeiculo} value={vDefaultValueVeiculo} onChange={handleChangeTipoVeiculo}>
                    </Select>
                </Form.Item>
                <Form.Item label="Quantidade lugares no veículo">
                    {vQtdLugares}

                </Form.Item>
                <Form.Item label="Data Transporte">
                    <DatePicker
                        onChange={onChangeData}
                        format="DD/MM/YYYY HH:mm:ss"

                        showTime={{
                            defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                        }}
                    />

                </Form.Item>
                <Form.Item label="Observações" name="lblObservacoes">
                    <TextArea rows={3} value={vObservacoes} onChange={handleInputChange} />
                </Form.Item>
                <Form.Item>
                    <Button onClick={fAtualizarCadastro}>Cadastrar</Button>
                    <Button onClick={fLimparForm}>Limpar</Button>
                </Form.Item>

            </Form>

            <Table dataSource={vPassageiros} pagination={false} columns={columnsPassageiros}></Table>
            

            <Modal title="Colaboradores" open={isModalOpenColaborador}  onOk={fFecharModalColaborador} onCancel={fFecharModalColaborador}>
            Nome colaborador: <Input value={vTextoLocalizar} onChange={(e) => { setTextoLocalizar(e.target.value) }} ></Input>
            <Button onClick={fFiltrarColaboradores} icon={<SearchOutlined></SearchOutlined>} >Filtrar</Button>
                        <Table dataSource={tabColaboradores} 
                        columns={[

                            {
                                title: 'Ação',
                                width: '15%',
                                render: (_, record) => {
                                    return (<>
                                    
                                    <Button onClick={() => 
                                        fSetColaboradores(record)
                                    }>Origem</Button>
                                    
                                    <Button onClick={() => 
                                        fSetColaboradoresDestino(record)
                                    }>Destino</Button>
                                    
                                    </>)
                                }
                    
                            },
                            {
                                title: 'Matricula',
                                dataIndex: 'matricula',
                                
                              },
                              {
                                title: 'Nome',
                                dataIndex: 'nome',
                                
                              },
                              {
                                title: 'Setor',
                                dataIndex: 'setor',
                                
                              },
                              {
                                title: 'Centro de Custos',
                                dataIndex: 'centroCusto',
                                
                              },

                              
            
                        ]}
                        ></Table>

            </Modal>


            <Modal title="Adicionar Passageiro" open={isModalOpenPassageiro} width={1000} onOk={handleOkPassageiro} onCancel={handleCancelPassageiro}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}

                    autoComplete="off"
                >   
                    <Form.Item label="Localizar Colaborador">
                        <Button onClick={fAbrirFiltroColaboradores} icon={<SearchOutlined  />}></Button>
                         
                    </Form.Item>
                    
                    <Form.Item label="Passageiro">
                        <Input value={vNomePass} onChange={(e) => { setNomePass(e.target.value) }}></Input>
                    </Form.Item>
                    <Form.Item label="Endereço Origem">
                        <Input value={vEnderecoPass} onChange={(e) => { setEnderecoPass(e.target.value) }}></Input>
                    </Form.Item>
                    <Form.Item label="Endereço Destino">
                        <Input value={vEnderecoDestino} onChange={(e) => { setEnderecoDestino(e.target.value) }}></Input>
                    </Form.Item>
                    <Form.Item label="Rota">
                        <CompSelectRotas pvEmpresa={vEmpresaSelecionada} onChange={(e) => { setRotaPass(e) }}></CompSelectRotas>
                    </Form.Item>
                    <Form.Item label="Observações">
                        <TextArea value={vObservacaoPass} onChange={(e) => { setObservacaoPass(e.target.value) }}></TextArea>
                    </Form.Item>
                </Form>
            </Modal>
        </>)
}

export default SolicTransporteExtra;