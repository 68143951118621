import React, { useEffect, useState } from 'react';
import { LogoutOutlined,DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined, HomeOutlined, LoginOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Button, Menu, Layout, theme, Image } from 'antd';

import SolicitacaoTransporteExtra from '../pages/solicitacaoTransporteExtraN';
import AlterarSenha from '../pages/alterarSenha'
import Login from '../component/login'
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";



import {
    logout as fSair,
    sessaoUsuarioBasico
} from '../services/serviceLogin'
const { Header, Content, Footer, Sider } = Layout;

const items = [
    getItem(<Link to="">Início</Link>, '00', <HomeOutlined />),
     getItem('Cadastros', '0', <DesktopOutlined />, 
     [
     getItem(<Link to="solicitacaoTransporteExtra">Solicitação Transporte Extra</Link>, '444'),
     
 
     
     ]),
     getItem('Relatórios', 'sub1', <PieChartOutlined/>, [
      getItem(<Link to="ExtratoTransporteExtra">Extrato Transporte Extra</Link>, '5'),
   
     ]),
     getItem('Usuário', 'sub2', <TeamOutlined />, [
       
       getItem(<Link to="login">Login</Link>, '9', <LoginOutlined />),
       getItem(<Link to="alterarSenha">Alterar Senha</Link>, '9.1'),
       getItem(<Link to="logout">Sair</Link>, '8', <LogoutOutlined />), 
       
     ]),
     
   ];

   function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
const App = () => {
    const [collapsed, setCollapsed] = useState(true);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [vDisabledCC, setDisabledCC] = useState(false)


    function handlerSair() {
        fSair()
        window.location.reload()
    }
    useEffect(() => {
        const iniciar = async () => {
            const vRetorno = await sessaoUsuarioBasico()
            vRetorno.nivelAcesso === 'adm' || vRetorno.nivelAcesso === 'rh' ? setDisabledCC(false) : setDisabledCC(true)
        }

        iniciar()
    })
    return (<>
        <Router>

            <Layout style={{ minHeight: '100vh' }}>
                <Header style={{ padding: 0, background: colorBgContainer, height: '1px' }} ></Header>
                <Layout>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />


                    </Sider>
                    <Content style={{ margin: '0 16px' }}>
                        {/* Link para a página de SolicitacaoTransporteExtra 
                        <Link to="/solicitacaoTransporteExtra">
                            <Button type="primary" shape="round" icon={<LogoutOutlined />} size="large"> Solicitação de Transporte Extra</Button>
                        </Link>
                    */}
                        {/* Botão de logout */}
                        {/*<Button type="primary" shape="round" onClick={handlerSair} icon={<LogoutOutlined />} size="large">Logout</Button>*/}

                        <br></br><br></br><br></br>
                        <div style={{ padding: 24, minHeight: 360, background: colorBgContainer, borderRadius: borderRadiusLG }}>
                        <Routes>
                            <Route path='/' element={<div style={{background: '#ffffff' }}><Image src='img/Andrade.jpg'></Image></div>} /> 
                            <Route path='/solicitacaoTransporteExtra' element={<SolicitacaoTransporteExtra idSolicitacaoTrasporteExtra="49" CCDisabled={vDisabledCC} />} />
                            <Route path='/alterarSenha' element={<AlterarSenha />} />
                            <Route path='/logout' element={<Button type="primary" shape="round" onClick={() => handlerSair()} icon={<LogoutOutlined />} size={10} >Logout</Button>} />
                            <Route path='/login' element={<Login />} />
                        </Routes>
                        </div>
                    </Content>

                </Layout>
                <Footer style={{ textAlign: 'center' }}>
                    Cláudio Rocha ©{new Date().getFullYear()} - claudioperesrocha@gmail.com
                </Footer>
            </Layout>



        </Router>
    </>)
}
export default App;