import { Select } from 'antd';
import React from 'react';
import { selecionar as selecionarEmpresa } from '../services/serviceEmpresa';

let vSelectEmpresas = []

export function fSelectEmpresas(){
  return vSelectEmpresas;
}

export function fLabelEmpresa(pIdEmpresa){
  //console.log('--> pIdEmpresa')
  //console.log(pIdEmpresa)
  const objetoEncontrado = vSelectEmpresas.find(objeto => objeto.value === pIdEmpresa);
  return objetoEncontrado.label
}

const SelectEmpresa = ({ value, disabled, onChange }) => {
  const [dados, setDados] = React.useState([]);

  async function getDados() {
    try {
      const dadosEmpresa = await selecionarEmpresa();
      //console.log(dadosEmpresa)
      vSelectEmpresas = dadosEmpresa
      setDados(dadosEmpresa)
      //setDados(dadosEmpresa.map(empresa => ({ value: empresa.idEmpresa, label: empresa.razaoSocial }))); // Supondo que cada empresa tenha 'id' e 'nome'
    } catch (error) {
      console.error('Erro ao buscar dados da empresa:', error);
    }
  }

  React.useEffect(() => {
    getDados();
  }, []);

  const handleChange = (selectedValue) => {
    onChange(selectedValue); // Notifica o componente pai sobre a seleção
  };

  return (
    <Select
      disabled={disabled}
      value={value} // Usa o valor passado pelo componente pai para controlar a seleção
      style={{ width: 300 }}
      onChange={handleChange}
      options={dados}
    />
  );
};

export default SelectEmpresa;
