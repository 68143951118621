
import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';

const vNomeAplicacao = "Extrato"
/* Rotas do servidor */
const vRotas = {
    extratoAdm: '/extratoAdm',
    excluirRotaExtrato: '/excluirRotaExtrato',
    inserirValorSugerido: '/inserirValorSugerido',
    selectRotasAdm: '/selectRotasAdm',
    extradoDetalhamento: '/extradoDetalhamento',
    tarifaExtrato: '/tarifaExtrato',
    inserirTarifaExtrato: '/inserirTarifaExtrato',
    excluirTarifaExtrato: '/excluirTarifaExtrato'
    
}

// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;

export async function excluirTarifaExtrato(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.excluirTarifaExtrato, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function inserirTarifaExtrato(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.inserirTarifaExtrato, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}
export async function inserirValorSugerido(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.inserirValorSugerido, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}


export async function excluirRota(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.excluirRotaExtrato, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function selectRotasAdm(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.selectRotasAdm, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}


export async function tarifaExtrato(pObj) {
    
    try {
        console.log('pObj')
    console.log(pObj)
        const response = await axios.post(urlAPI + vRotas.tarifaExtrato, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {
        console.log('error')
        console.log(error)
        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function extratoAdm(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.extratoAdm, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}

export async function extradoDetalhamento(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.extradoDetalhamento, pObj);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {
        
        return validarErro(error.response, vNomeAplicacao)

    }
}



export async function selecionar() {
    try {
        const response = await axios.get(urlAPI + vRotas.selecionar);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}
