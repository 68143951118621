import React from 'react';
import FileUpload from '../component/FileUploadColaboradores';
import Link from 'antd/es/typography/Link';

function App() {
  return (
    <div className="App">
      <h1>Carga colaboradores</h1>
      <FileUpload />
      <br></br><br></br>
      <a href='../docs/Modelo_Carga_Colaboradores.xlsx'>Download Modelo</a>
      
    </div>
  );
}

export default App;