
import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';

const vNomeAplicacao = "Mensagem"
/* Rotas do servidor */
const vRotas = {
    listar: '/listarMensagem',
    inserir: '/inserirMensagem',
    atualizar: '/atualizarMensagem',
    excluir: '/excluirMensagem'
}

// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;

export async function listar() {
    try {
        const response = await axios.get(urlAPI + vRotas.listar);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}


export async function excluir(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.excluir, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }


}
export async function inserir(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.inserir, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}

export async function atualizar(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.atualizar, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;

    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }
}




