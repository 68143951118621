import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';
const vNomeAplicacao = "Login"
const vRotas = {
    sessaoUsuario: '/sessaoUsuario',
    login: '/login',
    logout: '/logout',
    atualizarAcesso: '/atualizarAcesso',
    sessaoUsuarioBasico: '/sessaoUsuarioBasico'

}

// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;

export async function sessaoUsuario() {

    const response = await axios.get(urlAPI + vRotas.sessaoUsuario);
    //console.log('Dados service: ', response.data);
    return response.data;



}

export async function sessaoUsuarioBasico() {

    const response = await axios.get(urlAPI + vRotas.sessaoUsuarioBasico);
    //console.log('Dados service: ', response.data);
    return response.data;



}

export async function sessaoUsuarioxxxxx() {
    try {
        const response = await axios.get(urlAPI + vRotas.sessaoUsuario);
        console.log('Dados service: ', response.status);
        return response.data;
    } catch (error) {
        // Verifica se o erro tem uma resposta (ou seja, foi um erro de HTTP)
        if (error.response) {
            console.log('Erro no serviço: ', error.response.status);

            // Você pode tratar diferentes status de erro aqui, se necessário
            if (error.response.status === 401) {
                // Tratamento específico para status 401
            }

            // Retorna ou lança o erro, dependendo da sua necessidade
            return null; // ou throw error;
        } else {
            // Para erros que não são de HTTP
            console.error('Erro ao fazer a chamada HTTP:', error);
            return null; // ou throw error;
        }
    }


}

export async function login(pObj) {
    try {
        const response = await axios.post(urlAPI + vRotas.login, pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}

export async function atualizarAcesso() {
    const response = await axios.get(urlAPI + vRotas.atualizarAcesso)
    //console.log('Dados service: ', response.data)
    return response.data;
}

export async function logout() {

    const response = await axios.post(urlAPI + vRotas.logout)
    //console.log('Dados service: ', response.data)
    return response.data;


}