import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';

import { login as LogarUsuario } from '../services/serviceLogin';
import SelectEmpresa from '../component/selectEmpresa'

//import { useNavigate } from 'react-router-dom';
//import { useHistory } from 'react-router-dom';


const App = () => {
  //const history = useHistory();
  //const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [vEmpresaSelecionada,setEmpresaSelecionada] =  React.useState()
  
  const handleEmpresaChange = (value) => {
    setEmpresaSelecionada(value);
  };

  async function handleLogar() {
    console.log("Acesso Logar")
    const vRetorno = await LogarUsuario({
      "username": username,
      "password": password,
      "company": vEmpresaSelecionada //company
    });
    //console.log('vRetorno')
    //console.log(vRetorno)

    if (vRetorno.success){
      messageApi.open({
        type: 'success',
        content:  vRetorno.message,
      });
      //navigate('/');
      window.location.reload();
    
    }else{
      messageApi.open({
        type: 'error',
        content:  vRetorno.descricao,
      });
    }
    console.log(vRetorno);
  }

  const onFinish = (values) => {
    console.log('Success:', values);
    //navigate('/');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  React.useEffect(() => {
    //console.log('dddddd')
   

  }, []);

  return (
    
    <>
    
    {contextHolder}
   
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >

      <Form.Item label="Empresa">
        <SelectEmpresa value={vEmpresaSelecionada} onChange={handleEmpresaChange}></SelectEmpresa>
      </Form.Item>
      

      <Form.Item
        label="E-mail"
        name="username"
        rules={[{ required: true, message: 'Informe seu usuário!' }]}
      >
        <Input onChange={(e) => setUsername(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Senha"
        name="password"
        rules={[{ required: true, message: 'Informe sua senha!' }]}
      >
        <Input.Password onChange={(e) => setPassword(e.target.value)} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button onClick={handleLogar} type="primary" htmlType="submit">
          Autenticar
        </Button>
      </Form.Item>
    </Form>
    </>
  );
};

export default App;
