
import axios from 'axios';
import { urlAPI, validarErro } from '../component/utils';
const vNomeAplicacao = "Tarifa"

// Configuração global do Axios para enviar cookies
axios.defaults.withCredentials = true;

export async function listarTarifas() {
    try {
        const response = await axios.get(urlAPI + '/listarTarifas')
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {
        return validarErro(error.response, vNomeAplicacao)
    }

}

export async function selecionarTarifa(pObj) {
    try {
        const response = await axios.post(urlAPI + '/selecionarTarifa', pObj)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {
        return validarErro(error.response, vNomeAplicacao)
    }

}

export async function excluirTarifa(pTarifa) {
    try {
        const response = await axios.post(urlAPI + '/excluirTarifa', pTarifa)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}
export async function inserirTarifa(pTarifa) {
    try {
        const response = await axios.post(urlAPI + '/inserirTarifa', pTarifa)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}

export async function atualizarTarifa(pTarifa) {
    try {
        const response = await axios.post(urlAPI + '/atualizarTarifa', pTarifa)
        //console.log('Dados service: ', response.data)
        return response.data;
    } catch (error) {

        return validarErro(error.response, vNomeAplicacao)

    }

}




