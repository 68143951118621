

import React, { useEffect } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Form,
  Input,
  InputNumber,
  Table,
  Popconfirm
} from 'antd';


import { listarTarifas, excluirTarifa, inserirTarifa, atualizarTarifa } from '../services/serviceTarifa'
import SelectEmpresa, { fLabelEmpresa } from '../component/selectEmpresa'
import { formatCurrency } from '../component/utils'


const { TextArea } = Input;

const vTarifaBranco = { tarifa: '', key: 0, valor: '', descricao: '', idTarifa: 0, idEmpresa: '' }


const FormTarifa = () => {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setObjTarifa(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNumberChange = (value) => {
    setObjTarifa(prevState => ({
      ...prevState,
      valor: value
    }));
  };
  const cancel = (e) => {
    console.log(e);
    message.error('Cancelada exclusão');
  };
  const columnsData = [

    {
      title: 'Id',
      dataIndex: 'idTarifa',
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handlerTarifa(record.idTarifa)}></Button>
          <Popconfirm
            title="Excluir Registro"
            description="Você está certo que deseja excluir este registro?"
            onConfirm={() => handlerExcluirTarifa(record.idTarifa)}
            onCancel={cancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button icon={<DeleteOutlined />} ></Button>
          </Popconfirm>
        </>
      )
    },

    {
      title: 'Tarifa',
      dataIndex: 'tarifa',
    },

    {
      title: 'Valor',
      dataIndex: 'valor',
      render: (_, record) => {

        return (formatCurrency(record.valor))
      }
    },

    {
      title: 'Descrição',
      dataIndex: 'descricao',
    },

    {
      title: 'Id Empresa',
      dataIndex: 'idEmpresa',
      render: (_, record) => {

        return (<>{fLabelEmpresa(record.idEmpresa)}</>)
      }
    },

  ]


  const [messageApi, contextHolder] = message.useMessage();
  function handleLimpar() {
    setObjTarifa(vTarifaBranco)
    setEmpresaSelecionada(null)
  }
  function handlerTarifa(pId) {
    const objetoSelecionado = data.find(obj => obj.idTarifa === pId)
    setObjTarifa(objetoSelecionado)
    setEmpresaSelecionada(objetoSelecionado.idEmpresa)

  }

  async function handleCadastrar(pTarifa) {
    let vRetorno
    console.log("Tarifa: ")
    console.log(pTarifa)

    pTarifa.valor = pTarifa.valor.replace(',', '.')
    console.log(pTarifa)

    if (pTarifa.idTarifa === 0) {
      vRetorno = await inserirTarifa(pTarifa);
      setObjTarifa(vTarifaBranco)
      setEmpresaSelecionada()
    } else {
      vRetorno = await atualizarTarifa(pTarifa);
      setObjTarifa(vTarifaBranco)
      setEmpresaSelecionada()
    }

    console.log("Retorno: " + vRetorno)

    if ((vRetorno && vRetorno.status === 'Ok') || (vRetorno.success)) {
      messageApi.open({
        type: 'success',
        content: vRetorno.descricao,
      });

      callListarTarifa()

    } else {
      messageApi.open({
        type: 'error',
        content: vRetorno.descricao,
      });
    }
  }

  async function handlerExcluirTarifa(pId) {
    const objetoSelecionado = data.find(obj => obj.idTarifa === pId)
    const vRetorno = await excluirTarifa(objetoSelecionado);
    //messageApi.info(vRetorno.descricao);
    //console.log('dddddd ' + vRetorno)
    if (vRetorno.status === 'Ok') {
      messageApi.open({
        type: 'success',
        content: vRetorno.descricao,
      });

      callListarTarifa()

    } else {
      messageApi.open({
        type: 'error',
        content: vRetorno.descricao,
      });
    }




  }


  const [data, setData] = React.useState([])
  const [objTarifa, setObjTarifa] = React.useState({ vTarifaBranco })
  const [vEmpresaSelecionada, setEmpresaSelecionada] = React.useState()

  const handleEmpresaChange = (value) => {
    const vRetorno = objTarifa
    vRetorno.idEmpresa = value
    //setObjTarifa(vRetorno)
    setEmpresaSelecionada(value);
  };



  async function callListarTarifa() {
    const vRetorno = await listarTarifas()
    if (vRetorno && vRetorno.status) {
      if (!vRetorno.status === 'erro') {
        setData(vRetorno)
      } else {
        messageApi.open({
          type: 'error',
          content: vRetorno.descricao,
        });
      }
    } else {
      setData(vRetorno)
    }

    //console.log('retorno')
    //console.log(vRetorno)

  }



  useEffect(() => {
    // Coloque aqui o código que você quer executar logo após o app iniciar
    callListarTarifa()
    setObjTarifa(vTarifaBranco)
    console.log('O aplicativo foi iniciado!');
  }, []);


  return (

    <>
      {contextHolder}
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        disabled={false}
        style={{ maxWidth: 600 }}
      >


        <Form.Item label="Empresa">
          <SelectEmpresa value={vEmpresaSelecionada} onChange={handleEmpresaChange}></SelectEmpresa>

        </Form.Item>
        <Form.Item label="Tarifa">
          <Input
            name="tarifa"
            value={objTarifa.tarifa}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item label="Valor">
          <Input
            name="valor"
            value={objTarifa.valor}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item label="Descrição">
          <TextArea
            name="descricao"
            value={objTarifa.descricao}
            rows={4}
            placeholder="Descrever detalhes do veículos"
            onChange={handleInputChange}
          />
        </Form.Item>


        <Form.Item label="">
          <Button onClick={() => handleCadastrar(objTarifa)}>Cadastrar</Button>
          <Button onClick={handleLimpar}>Limpar</Button>
        </Form.Item>
      </Form>

      <Table dataSource={data} columns={columnsData}></Table>
    </>
  );



};


export default FormTarifa;