import React, { useState } from 'react';
import axios from 'axios';
import {urlAPI} from '../component/utils'

const FileUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => { 
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      axios.defaults.withCredentials = true;
      const res = await axios.post( urlAPI + '/uploadColaboradores', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert(res.data.message);
      console.log(res)
    } catch (err) {
      alert('Error uploading file');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default FileUpload;
